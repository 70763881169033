<template>
  <header
    v-if="typeof $store.state.deviceWidth === 'number'"
    :class="{ s_nav: $route.path.indexOf('public-benefit') !== -1 }"
  >
    <template v-if="$store.state.deviceWidth > 768">
      <div class="top">
        <ul class="container">
          <li
            v-for="(item, index) in dataNav.filter(nav => !nav.primary)"
            :key="index"
            @click.stop="PHandleClickNavItem(item)"
            :class="{ active: item.route && route.indexOf(item.route) !== -1 }"
          >
            {{ item.label }}
            <i class="iconfont icon-arrow-down" v-if="item.children"></i>
            <div v-if="item.children" class="business_sys">
              <ol class="container">
                <li v-for="(_item, _index) in item.children" :key="_index">
                  {{ _item.label }}
                  <div
                    class="third_level"
                    v-if="_item.children && _item.children.length"
                  ></div>
                </li>
              </ol>
              <img alt="" src="@/assets/images/header/business_sys_n.jpg" />
            </div>
          </li>
        </ul>
      </div>
      <div
        :class="[
          'nav',
          {
            'nav--white': $store.state.scrollTop > 30,
            'nav--transparent': $store.state.pcHeaderIsTransparent,
          },
        ]"
      >
        <div class="container">
          <img
            :src="$store.state.scrollTop > 30 ? LogoWords : LogoWordsWhite"
            alt="晓羊集团"
            @click="$router.push('/home')"
            class="logo"
          />
          <ul>
            <li
              v-for="(item, index) in dataNav.filter(nav => !!nav.primary)"
              :key="index"
              @click.stop="PHandleClickNavItem(item)"
              @mouseleave="hideThidMenu"
              :class="{ actived: route.indexOf(item.route) !== -1 }"
            >
              {{ item.label }}
              <template v-if="item.children">
                <div class="icon-item">
                  <div class="triangle"></div>
                  <!-- <i class="iconfont icon-arrow-up-fill"></i> -->
                </div>
                <div
                  class="menus"
                  :style="{ display: navIndex === index ? 'none' : '' }"
                >
                  <!-- :style="{
                      height:
                        currentIndex === -1 || currentIndex === 1
                          ? '110px'
                          : currentIndex === 0
                          ? '320px'
                          : '260px',
                    }" -->
                  <ol>
                    <li
                      v-for="(_item, _index) in item.children"
                      :key="_index"
                      @mouseover="showSolution(_item, _index)"
                      :class="{ actived: route.indexOf(_item.route) !== -1 }"
                      @click.stop="PHandleClickNavItem(_item, index)"
                    >
                      <span>{{ _item.label }}</span>
                      <div
                        class="third_level"
                        @mouseleave="hideThidMenu"
                        v-if="_item.children && _item.children.length"
                      >
                        <div
                          v-for="(item2, index2) in _item.children"
                          :key="index2"
                          @click.stop="goSolutionPage(item2.route, index)"
                          :class="{
                            actived: route.indexOf(item2.route) !== -1,
                          }"
                        >
                          {{ item2.label }}
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-else>
      <img
        alt="晓羊集团"
        src="./../assets/images/header/logo_words.png"
        @click="$router.push('/home')"
      />
      <span class="menu" @click="openPreview">业务体系</span>
      <span
        class="menu"
        :class="{
          visited: route.indexOf('/partners-customers') !== -1,
        }"
        @click="$router.push('/partners-customers/partners')"
        >伙伴与客户</span
      >
      <span class="menu" @click.stop="MToggleNav()">了解更多</span>
      <!-- <i class="iconfont icon-bars" @click.stop="MToggleNav()"></i> -->
      <!-- <vue-preview
        :slides="thumbsList"
        class="imgPrev"
        v-show="visible"
      ></vue-preview> -->
      <img
        src="@/assets/images/header/business_sys_n.jpg"
        preview="1"
        preview-text="业务体系"
        title="点击查看大图"
        id="preview"
        v-show="visible"
      />
      <div class="nav">
        <img src="./../assets/images/header/logo.png" />
        <p>
          <span>
            <i
              v-show="MDataNavActive !== dataNav"
              class="fs-26 iconfont icon-arrow-left"
              @click.stop="MInitDataNavActive"
            ></i>
          </span>
          <i
            class="fs-26 iconfont icon-times"
            @click.stop="MToggleNav('hide')"
          ></i>
        </p>
        <ul>
          <li
            v-for="(nav, index) in MDataNavActive"
            :key="index"
            :class="[{ primary: nav.primary }]"
            @click.stop="MHandleClickNavItem(nav)"
          >
            {{ nav.label }}
          </li>
        </ul>
      </div>
    </template>
  </header>
</template>

<script>
import LogoWordsWhite from '@/assets/images/header/logo_words_white.png'
import LogoWords from '@/assets/images/header/logo_words.png'
import { dataNav, dataNavM } from '@/data/header.js'

export default {
  name: 'Header',
  data() {
    return {
      //logo
      LogoWords,
      LogoWordsWhite,
      visible: false,
      //导航数据
      dataNav,
      showThidMenu: false,
      currentIndex: -1,
      //pc当前选择的nav
      PDataNavActive: null,
      //mobile当前活动的nav
      MDataNavActive: null,
      route: '',
      navIndex: -1,
    }
  },
  created() {
    if (this.$store.state.deviceWidth <= 768) {
      // 初始化移动端nav
      this.MInitDataNavActive()
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        console.log(val)
        this.route = this.filterPath(val)
      },
    },
  },
  mounted() {
    // 获取新闻中心下的分类
    this.getNewsChildren()
  },
  methods: {
    openPreview() {
      document.getElementById('preview').click()
    },
    filterPath(obj) {
      let path = obj.path
      let query = obj.query
      if (obj.hash) path += obj.hash
      if (Object.keys(query).length > 0) {
        let params = ''
        for (const key in query) {
          params += `${params ? '&' : '?'}${key}=${query[key]}`
        }
        path += params
      }
      return path
    },
    showSolution(item, index) {
      if (item.children) {
        this.currentIndex = index
      } else {
        this.currentIndex = -1
      }
    },
    hideThidMenu() {
      this.currentIndex = -1
    },
    goSolutionPage(route, index) {
      this.navIndex = index
      setTimeout(() => {
        this.navIndex = -1
      }, 100)
      this.$router.push(route)
    },
    getNewsChildren() {
      this.$axios
        .post('/category/list', {
          pid: 2,
        })
        .then(res => {
          let newList = this.dataNav.find(
            item => item.label == '新闻中心'
          ).children
          let arr = []
          newList.forEach(e => {
            res.forEach(el => {
              if (e.label === el.name) {
                arr.push(el)
              }
            })
          })
          this.dataNav.find(item => item.label == '新闻中心').children =
            arr.map(_item => {
              if (newList.find(item => item.label === _item.name)) {
                return {
                  label: _item.name,
                  route: newList.find(item => item.label === _item.name).route,
                  id: _item.id,
                }
              } else {
                return {
                  label: _item.name,
                  route: '/news/list?id=' + _item.id,
                  id: _item.id,
                }
              }
            })
        })
    },
    /* ----------------------------------------------------------------
      pc端方法
    ----------------------------------------------------------------- */
    /**
     * 点击nav
     */
    PHandleClickNavItem(nav, index) {
      this.navIndex = index
      setTimeout(() => {
        this.navIndex = -1
      }, 100)
      if (!nav.children || nav.children.length === 0)
        this.$router.push(nav.route)
    },
    /* ----------------------------------------------------------------
      mobile端方法
    ----------------------------------------------------------------- */
    /**
     * 显示隐藏nav
     */
    MToggleNav(state) {
      const classList = document.querySelector('.nav').classList
      switch (state) {
        case 'show':
          this.MInitDataNavActive()
          classList.add('active')
          break
        case 'hide':
          classList.remove('active')
          break
        default:
          this.MToggleNav('show')
          break
      }
    },
    /**
     * 初始化nav
     */
    MInitDataNavActive() {
      this.MDataNavActive = dataNavM
    },
    /**
     * 点击nav
     */
    MHandleClickNavItem(nav) {
      if (nav.children) {
        if (nav.children.children) {
          this.MDataNavActive = nav.children.children
        } else {
          this.MDataNavActive = nav.children
        }
        console.log(this.MDataNavActive)
      } else {
        this.$router.push(nav.route)
        this.MToggleNav('hide')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/header.scss';
</style>
