import axios from 'axios'
import _ from 'lodash'

axios.defaults.timeout = 0
// axios.defaults.baseURL =
//   process.env.NODE_ENV === 'development'
//     ? process.env.VUE_APP_API
//     : process.env.VUE_APP_BASEURL
axios.defaults.baseURL = process.env.VUE_APP_BASEURL

// 对请求参数做处理
axios.interceptors.request.use(async config => {
  // config.data 去除没有意义的项并且序列化
  config.data = JSON.stringify(
    _.isObject(config.data)
      ? _.omitBy(_.omitBy(config.data, _.isNull), _.isUndefined)
      : config.data
  )
  config.data = config.data ? JSON.parse(config.data) : config.data
  return config
})

// 响应拦截器即异常处理
axios.interceptors.response.use(
  response => {
    if (response) {
      const errMes = response.data.msg
      switch (response.data.code) {
        case 400:
          console.error(errMes || '错误请求')
          break
        case 401:
          console.error(errMes || '用户未登录')
          break
        case 403:
          console.error(errMes || '拒绝访问')
          break
        case 404:
          console.error(errMes || '请求错误,未找到该资源')
          break
        case 405:
          console.error(errMes || '请求方法未允许')
          break
        case 406:
          console.error(errMes || '请求方法不被接受')
          break
        case 408:
          console.error(errMes || '请求超时')
          break
        case 500:
          console.error(errMes || '服务器端出错')
          break
        case 501:
          console.error(errMes || '网络未实现')
          break
        case 502:
          console.error(errMes || '网络错误')
          break
        case 503:
          console.error(errMes || '服务不可用')
          break
        case 504:
          console.error(errMes || '网络超时')
          break
        case 505:
          console.error(errMes || 'http版本不支持该请求')
          break
        case 200:
          if (response.headers['content-type'].includes('application/json')) {
            response.data =
              typeof response.data === 'string'
                ? JSON.parse(response.data)
                : response.data
            return response
          }
          break
        default:
          console.error(errMes || `连接错误${response.data.code}`)
          break
      }
      if (response.data.code !== 200) {
        {
          return Promise.reject(response)
        }
      }
    }
    return Promise.resolve(response)
  },
  err => {
    if (err && err.response) {
      const errMes = err.response.data.message
      switch (err.response.status) {
        case 400:
          console.error(errMes || '错误请求')
          break
        case 401:
          console.error(errMes || '用户未登录')
          break
        case 403:
          console.error(errMes || '拒绝访问')
          break
        case 404:
          console.error(errMes || '请求错误,未找到该资源')
          break
        case 405:
          console.error(errMes || '请求方法未允许')
          break
        case 406:
          console.error(errMes || '请求方法不被接受')
          break
        case 408:
          console.error(errMes || '请求超时')
          break
        case 500:
          console.error(errMes || '服务器端出错')
          break
        case 501:
          console.error(errMes || '网络未实现')
          break
        case 502:
          console.error(errMes || '网络错误')
          break
        case 503:
          console.error(errMes || '服务不可用')
          break
        case 504:
          console.error(errMes || '网络超时')
          break
        case 505:
          console.error(errMes || 'http版本不支持该请求')
          break
        default:
          console.error(errMes || `连接错误${err.response.status}`)
          break
      }
      return Promise.reject(err)
    }
    return Promise.reject(err)
  }
)

/**
 * 封装get方法
 * @param {string} url - 接口地址
 * @param {object} params = {} - 接口参数
 * @returns {*} Promise
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(response => {
        resolve(response.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param {string} url - 接口地址
 * @param {object} data = {} - 接口参数
 * @returns {*} Promise
 */
export function post(url, data = {}, config) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config).then(
      response => {
        resolve(response.data.data)
      },
      err => {
        reject(err)
      }
    )
  })
}
