import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: '首页',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/about',
    name: '关于我们',
    meta: {
      title: '关于我们',
    },
    component: () => import('@/views/about/About.vue'),
  },
  {
    path: '/partners-customers',
    name: '伙伴与客户',
    meta: {
      title: '伙伴与客户',
    },
    redirect: '/partners-customers/partners',
    component: () => import('@/views/partners/Index.vue'),
    children: [
      {
        path: 'partners',
        name: '伙伴与客户-主页',
        meta: {
          title: '伙伴与客户',
        },
        component: () => import('@/views/partners/Partners.vue'),
      },
      {
        path: 'school',
        name: '部分合作学校',
        meta: {
          title: '伙伴与客户-部分合作学校',
        },
        component: () => import('@/views/partners/School.vue'),
      },
      {
        path: 'business',
        name: '合作企业',
        meta: {
          title: '伙伴与客户-合作企业',
        },
        component: () => import('@/views/partners/Business.vue'),
      },
      {
        path: 'case',
        name: '经典案例',
        meta: {
          title: '伙伴与客户-经典案例',
        },
        component: () => import('@/views/partners/Case.vue'),
      },
      {
        path: 'case/case1',
        name: '四川省天府七中',
        meta: {
          title: '四川省天府七中',
        },
        component: () => import('@/views/partners/cases/case1.vue'),
      },
      {
        path: 'case/case2',
        name: '北京市陈经纶中学',
        meta: {
          title: '北京市陈经纶中学',
        },
        component: () => import('@/views/partners/cases/case2.vue'),
      },
      {
        path: 'case/case3',
        name: '北京市第三十五中学',
        meta: {
          title: '北京市第三十五中学',
        },
        component: () => import('@/views/partners/cases/case3.vue'),
      },
      {
        path: 'case/case4',
        name: '东莞市松山湖北区学校',
        meta: {
          title: '东莞市松山湖北区学校',
        },
        component: () => import('@/views/partners/cases/case4.vue'),
      },
      {
        path: 'case/case5',
        name: '南京市上元中学',
        meta: {
          title: '南京市上元中学',
        },
        component: () => import('@/views/partners/cases/case5.vue'),
      },
      {
        path: 'case/case6',
        name: '安徽省芜湖市南陵县智慧教育云平台',
        meta: {
          title: '安徽省芜湖市南陵县智慧教育云平台',
        },
        component: () => import('@/views/partners/cases/case6.vue'),
      },
      {
        path: 'case/case7',
        name: '深圳市南山区香山里小学',
        meta: {
          title: '深圳市南山区香山里小学',
        },
        component: () => import('@/views/partners/cases/case7.vue'),
      },
      {
        path: 'case/case8',
        name: '四川省宣汉中学',
        meta: {
          title: '四川省宣汉中学',
        },
        component: () => import('@/views/partners/cases/case8.vue'),
      },
      {
        path: 'case/case9',
        name: '宜昌市青少年教育实践教育基地',
        meta: {
          title: '宜昌市青少年教育实践教育基地',
        },
        component: () => import('@/views/partners/cases/case9.vue'),
      },
      {
        path: 'case/case10',
        name: '涿州市实验中学',
        meta: {
          title: '涿州市实验中学',
        },
        component: () => import('@/views/partners/cases/case10.vue'),
      },
      {
        path: 'case/case11',
        name: '四川省成都市武侯区教育局',
        meta: {
          title: '四川省成都市武侯区教育局',
        },
        component: () => import('@/views/partners/cases/case11.vue'),
      },
      {
        path: 'case/case12',
        name: '湾址县智慧教育云平台',
        meta: {
          title: '湾址县智慧教育云平台',
        },
        component: () => import('@/views/partners/cases/case12.vue'),
      },
      {
        path: 'case/case13',
        name: '南陵县智慧教育云平台',
        meta: {
          title: '南陵县智慧教育云平台',
        },
        component: () => import('@/views/partners/cases/case13.vue'),
      },
      {
        path: 'case/case14',
        name: '北京市门头沟区',
        meta: {
          title: '北京市门头沟区',
        },
        component: () => import('@/views/partners/cases/case14.vue'),
      },
      {
        path: 'case/case15',
        name: '湖南省张家界市桑植县',
        meta: {
          title: '湖南省张家界市桑植县',
        },
        component: () => import('@/views/partners/cases/case15.vue'),
      },
      {
        path: 'case/case16',
        name: '四川省泸州市江阳区、合江县',
        meta: {
          title: '四川省泸州市江阳区、合江县',
        },
        component: () => import('@/views/partners/cases/case16.vue'),
      },
      {
        path: 'case/case17',
        name: '四川师范大学附属实验学校',
        meta: {
          title: '四川师范大学附属实验学校',
        },
        component: () => import('@/views/partners/cases/case17.vue'),
      },
      {
        path: 'case/case18',
        name: '山东、四川、内蒙古多地区',
        meta: {
          title: '山东、四川、内蒙古多地区',
        },
        component: () => import('@/views/partners/cases/case18.vue'),
      },
    ],
  },
  {
    path: '/products-plans',
    name: '产品及方案',
    meta: {
      title: '产品及方案',
    },
    redirect: '/products-plans/school',
    component: () => import('@/views/productsPlans/Index.vue'),
    children: [
      {
        path: 'school',
        name: '校端产品',
        meta: {
          title: '产品及方案',
        },
        component: () => import('@/views/productsPlans/School.vue'),
      },
      {
        path: 'area',
        name: '区域产品',
        meta: {
          title: '产品及方案',
        },
        component: () => import('@/views/productsPlans/Area.vue'),
      },
      {
        path: 'safety',
        name: '区域产品',
        meta: {
          title: '产品及方案',
        },
        component: () => import('@/views/productsPlans/AreaSafety.vue'),
      },
      {
        path: 'afterschool',
        name: '区域产品',
        meta: {
          title: '产品及方案',
        },
        component: () => import('@/views/productsPlans/AreaAfterSchool.vue'),
      },
      {
        path: 'solution',
        name: '整体产品解决方案',
        meta: {
          title: '产品及方案',
        },
        component: () => import('@/views/productsPlans/Solution.vue'),
      },
    ],
  },
  {
    path: '/scene',
    name: '应用场景',
    meta: {
      title: '应用场景',
    },
    redirect: '/scene/student-grow',
    component: () => import('@/views/scene/Index.vue'),
    children: [
      {
        path: 'student-grow',
        name: '学生个性化成长',
        meta: {
          title: '应用场景',
        },
        component: () => import('@/views/scene/StudentGrow.vue'),
      },
      {
        path: 'teacher-development',
        name: '教师专业发展',
        meta: {
          title: '应用场景',
        },
        component: () => import('@/views/scene/TeacherDevelopment.vue'),
      },
      {
        path: 'education-manage_evaluate',
        name: '教育管理与评价',
        meta: {
          title: '应用场景',
        },
        component: () => import('@/views/scene/EducationManAndEvaluate.vue'),
      },
      {
        path: 'green_campus',
        name: '绿色校园',
        meta: {
          title: '应用场景',
        },
        component: () => import('@/views/scene/GreenCampus.vue'),
      },
      {
        path: 'social_service',
        name: '社会服务',
        meta: {
          title: '应用场景',
        },
        component: () => import('@/views/scene/SocialService.vue'),
      },
      {
        path: 'safe_campus',
        name: '平安校园',
        meta: {
          title: '应用场景',
        },
        component: () => import('@/views/scene/SafeCampus.vue'),
      },
    ],
  },
  {
    path: '/news',
    name: '新闻中心',
    meta: {
      title: '新闻中心',
    },
    redirect: '/news/policy-track',
    component: () => import('@/views/news/Index.vue'),
    children: [
      {
        path: 'policy-track',
        name: '新闻中心-政策追踪',
        meta: {
          title: '新闻中心',
        },
        component: () => import('@/views/news/PolicyTrack.vue'),
      },
      {
        path: 'group-dynamics',
        name: '新闻中心-集团动态',
        meta: {
          title: '新闻中心',
        },
        component: () => import('@/views/news/GroupDynamics.vue'),
      },
      {
        path: 'trade-news',
        name: '新闻中心-行业新闻',
        meta: {
          title: '新闻中心',
        },
        component: () => import('@/views/news/TradeNews.vue'),
      },
      {
        path: 'public-benefit',
        name: '新闻中心-晓羊公益行',
        meta: {
          title: '新闻中心',
        },
        component: () => import('@/views/news/PublicBenefit.vue'),
      },
      {
        path: 'detail',
        name: '新闻详情',
        meta: {
          title: '新闻详情',
        },
        component: () => import('@/views/news/Detail.vue'),
      },
    ],
  },
  {
    path: '/service',
    name: '服务与支持',
    meta: {
      title: '服务与支持',
    },
    component: () => import('@/views/serviceSupport/ServiceSupport.vue'),
    children: [],
  },
  {
    path: '/consult',
    name: '项目咨询',
    meta: {
      title: '项目咨询',
    },
    component: () => import('@/views/Consult.vue'),
    children: [],
  },
  {
    path: '/institute',
    name: '研究院',
    meta: {
      title: '研究院',
    },
    redirect: '/institute/intro',
    component: () => import('@/views/institute/Index.vue'),
    children: [
      {
        path: 'intro',
        name: '研究院概况',
        meta: {
          title: '研究院概况',
        },
        component: () => import('@/views/institute/Intro.vue'),
      },
      {
        path: 'internal_publication',
        name: '内刊',
        meta: {
          title: '内刊',
        },
        component: () => import('@/views/institute/internal_publication.vue'),
      },
      {
        path: 'technology_Institute',
        name: '晓羊技术研究院',
        meta: {
          title: '晓羊技术研究院',
        },
        component: () => import('@/views/institute/technology_Institute.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/home',
  },
]

// 解决控制台重复点击路由的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 解决控制台重复点击路由的报错
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // hash路由模式下，重置页面滚动条
  if (to.path !== from.path) {
    window.scrollTo(0, 0)
  }
  store.commit('setPcHeaderIsTransparent')
  window.document.title = to.meta.title
  next()
})

export default router
