import Vue from 'vue'
import Vuex from 'vuex'
import * as Request from '../utils/Request'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    deviceWidth: 1920, // 设备宽度
    scrollTop: 0, // 设备卷去高度
    pcHeaderIsTransparent: false, // header背景是否透明
    baseInfo: {}, // 官方基本信息
  },
  mutations: {
    setPcHeaderIsTransparent(state, value = false) {
      state.pcHeaderIsTransparent = value
    },
    setDeviceWidth(state, value) {
      state.deviceWidth = value
    },
    setScrollTop(state, value) {
      state.scrollTop = value
    },
    setBaseInfo(state, value) {
      state.baseInfo = value
    },
  },
  actions: {
    /**
     * @param commit
     * @return {*}
     */
    setBaseInfoAsync({ commit }) {
      Request.get('/basic/info').then(res => {
        console.log(res)
        const data = res
        // 超过万的调整数值，设置单位
        for (const key in data) {
          if (typeof data[key] === 'number') {
            let num = data[key]
            let numStr = num.toString()
            if (numStr.length > 8) {
              data[key] = Math.round(data[key] / 100000000)
              data[`${key}Unit`] = '亿'
            } else if (numStr.length < 9 && numStr.length > 5)
              if (data[key] / 10000 > 1) {
                data[key] = Math.round(data[key] / 10000)
                data[`${key}Unit`] = '万'
              } else {
                data[`${key}Unit`] = ''
              }
          }
        }
        commit('setBaseInfo', data)
      })
    },
  },
  modules: {},
})
