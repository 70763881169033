export const dataNav = [
  {
    label: '首页',
    primary: true,
    route: '/home',
  },
  {
    label: '关于晓羊',
    primary: true,
    route: '/about',
    // children: [
    //   {
    //     label: '董事长兼CEO致辞',
    //     route: '',
    //   },
    //   {
    //     label: '管理团队',
    //     route: '',
    //   },
    //   {
    //     label: '集团概况',
    //     route: '',
    //   },
    //   {
    //     label: '荣誉资质',
    //     route: '',
    //   },
    //   {
    //     label: '大事记',
    //     route: '',
    //   },
    //   {
    //     label: '企业愿景',
    //     route: '',
    //   },
    // ],
  },
  {
    label: '产品及方案',
    primary: true,
    route: '/products-plans',
    children: [
      {
        label: '校端产品',
        route: '/products-plans/school',
        children: [
          {
            label: '一人一课表',
            route: '/products-plans/school?name=onePersonOneTimetableComponent',
          },
          {
            label: '数据中台',
            route: '/products-plans/school?name=dataPlatformComponent',
          },
          {
            label: '控量提质作业系统',
            route: '/products-plans/school?name=qualityManageSystem',
          },
          // {
          //   label: '“五育并举”评价系统',
          //   route: '/products-plans/school?name=fiveEduComment',
          // },
          {
            label: '智笔课堂互动系统',
            route: '/products-plans/school?name=smartClassroom',
          },
          {
            label: '校园安全综合管理平台',
            route: '/products-plans/school?name=safetySchool',
          },
          // {
          //   label: '晓慧XMUI云屏',
          //   route: '/products-plans/school?name=aICloudScreen',
          // },
          // {
          //   label: '晓慧智慧云黑板',
          //   route: '/products-plans/school?name=AICloudBlackboard',
          // },
          {
            label: '校园智能物联管控平台',
            route: '/products-plans/school?name=Monitor',
          },
          {
            label: '晓羊学苑',
            route: '/products-plans/school?name=College',
          },
          {
            label: '教师发展平台',
            route: '/products-plans/school?name=Teacher',
          },
          {
            label: '学业质量管理平台',
            route: '/products-plans/school?name=AcademicQuality',
          },
        ],
      },
      {
        label: '区域产品',
        route: '/products-plans/area',
        children: [
          {
            label: '区域课程管理平台',
            route: '/products-plans/area?name=CourseManage',
          },
          {
            label: '区域校园安全风险防控平台',
            route: '/products-plans/area?name=Safety',
          },
          {
            label: '区域课后服务云平台',
            route: '/products-plans/area?name=AfterSchool',
          },
          {
            label: '区域学业质量管理平台',
            route: '/products-plans/area?name=AcademicQuality',
          },
          {
            label: '区域智能物联管控平台',
            route: '/products-plans/area?name=SmartUnion',
          },
        ],
      },
      {
        label: '整体解决方案',
        route: '/products-plans/solution',
        children: [
          {
            label: '新高考一站式解决方案',
            route: '/products-plans/solution?name=NewCollegeEntrance',
          },
          {
            label: '集团校管理解决方案',
            route: '/products-plans/solution?name=SchoolGroups',
          },
          {
            label: '五育并举评价整体解决方案（小学版）',
            route: '/products-plans/solution?name=ElementarySchoolVersion',
          },
          {
            label: '五育并举评价整体解决方案（中学版）',
            route: '/products-plans/solution?name=SecondarySchoolVersion',
          },
          {
            label: '绿色校园整体解决方案',
            route: '/products-plans/solution?name=GreenCampus',
          },
          {
            label: '平安校园整体解决⽅案',
            route: '/products-plans/solution?name=SafeCampus',
          },
          {
            label: '智慧研学综合实践一站式解决方案',
            route: '/products-plans/solution?name=SmartStudy',
          },
          {
            label: '家校社一体化劳动教育解决方案',
            route: '/products-plans/solution?name=Integrated',
          },
          // {
          //   label: '晓慧XMUI AIoT平台解决方案',
          //   route: '/products-plans/solution?name=XiaohuiAIOT',
          // },
          {
            label: '教师发展解决方案',
            route: '/products-plans/solution?name=TeacherDevelopment',
          },
          {
            label: '课后服务解决方案',
            route: '/products-plans/solution?name=AfterClassService',
          },
          {
            label: '智笔课堂互动解决方案',
            route: '/products-plans/solution?name=IntelligentPenClassroom',
          },
          {
            label: '学业质量管理解决方案',
            route: '/products-plans/solution?name=AcademicQuality',
          },
        ],
      },
    ],
  },
  {
    label: '应用场景',
    primary: true,
    route: '/scene',
    children: [
      {
        label: '学生个性成长',
        route: '/scene/student-grow',
      },
      {
        label: '教师专业发展',
        route: '/scene/teacher-development',
      },
      {
        label: '教育管理与评价',
        route: '/scene/education-manage_evaluate',
      },
      {
        label: '绿色校园',
        route: '/scene/green_campus',
      },
      {
        label: '平安校园',
        route: '/scene/safe_campus',
      },
      {
        label: '社会服务',
        route: '/scene/social_service',
      },
    ],
  },
  {
    label: '服务与支持',
    primary: true,
    route: '/service',
    children: [
      {
        label: '服务解读',
        route: '/service#interpretaion',
      },
      {
        label: '服务能力',
        route: '/service#ability',
      },
      {
        label: '服务地图',
        route: '/service#map',
      },
    ],
  },
  {
    label: '研究院',
    primary: true,
    route: '/institute',
    children: [
      {
        label: '晓羊教育研究院',
        route: '/institute/intro#overview',
      },
      // {
      //   label: '资源优势',
      //   route: '/institute/intro#resource',
      // },
      // {
      //   label: '核⼼业务',
      //   route: '/institute/intro#core',
      // },
      // {
      //   label: '教育研修项目类型',
      //   route: '/institute/intro#edu_type',
      // },
      {
        label: '晓羊技术研究院',
        route: '/institute/technology_Institute',
      },
      {
        label: '内刊',
        route: '/institute/internal_publication',
      },
    ],
  },
  {
    label: '新闻中心',
    primary: true,
    route: '/news',
    children: [
      {
        label: '集团动态',
        route: '/news/group-dynamics',
      },
      {
        label: '行业新闻',
        route: '/news/trade-news',
      },
      {
        label: '政策追踪',
        route: '/news/policy-track',
      },
      {
        label: '晓羊公益行',
        route: '/news/public-benefit',
      },
    ],
  },
  {
    label: '业务体系',
    primary: false,
    route: '',
    children: [
      {
        label: '福建晓⽺',
        route: '',
      },
      {
        label: '北京牧迪科技',
        route: '',
      },
      {
        label: '重庆晓⽺智教',
        route: '',
      },
      {
        label: '江苏晓⽺',
        route: '',
      },
      {
        label: '四川智优⽺',
        route: '',
      },
      {
        label: '⼴⻄晓⽺',
        route: '',
      },
      {
        label: '湖南⼀数',
        route: '',
      },
      {
        label: '北京晓⽺策学',
        route: '',
      },
      {
        label: '⽆锡晓⽺',
        route: '',
      },
      {
        label: '北京晓⽺和讯',
        route: '',
      },
      {
        label: '北京阅⽺科技',
        route: '',
      },
      {
        label: '天津晓⽺',
        route: '',
      },
    ],
  },
  {
    label: '伙伴与客户',
    primary: false,
    route: '/partners-customers',
    // children: [
    //   {
    //     label: '合作企业',
    //     route: '',
    //   },
    //   {
    //     label: '合作学校',
    //     route: '',
    //   },
    //   {
    //     label: '经典案例',
    //     route: '',
    //   },
    // ],
  },
]
export const dataNavM = [
  {
    label: '首页',
    primary: true,
    route: '/home',
  },
  {
    label: '关于晓羊',
    primary: true,
    route: '/about',
    // children: [
    //   {
    //     label: '董事长兼CEO致辞',
    //     route: '',
    //   },
    //   {
    //     label: '管理团队',
    //     route: '',
    //   },
    //   {
    //     label: '集团概况',
    //     route: '',
    //   },
    //   {
    //     label: '荣誉资质',
    //     route: '',
    //   },
    //   {
    //     label: '大事记',
    //     route: '',
    //   },
    //   {
    //     label: '企业愿景',
    //     route: '',
    //   },
    // ],
  },
  {
    label: '产品及方案',
    primary: true,
    route: '/products-plans',
    children: [
      {
        label: '校端产品',
        route: '/products-plans/school',
        children: [
          {
            label: '一人一课表',
            route: '/products-plans/school?name=onePersonOneTimetableComponent',
          },
          {
            label: '数据中台',
            route: '/products-plans/school?name=dataPlatformComponent',
          },
          {
            label: '控量提质作业系统',
            route: '/products-plans/school?name=qualityManageSystem',
          },
          // {
          //   label: '“五育并举”评价系统',
          //   route: '/products-plans/school?name=fiveEduComment',
          // },
          {
            label: '智笔课堂互动系统',
            route: '/products-plans/school?name=smartClassroom',
          },
          {
            label: '校园安全综合管理平台',
            route: '/products-plans/school?name=safetySchool',
          },
          // {
          //   label: '晓慧XMUI云屏',
          //   route: '/products-plans/school?name=aICloudScreen',
          // },
          // {
          //   label: '晓慧智慧云黑板',
          //   route: '/products-plans/school?name=AICloudBlackboard',
          // },
          {
            label: '校园智能物联管控平台',
            route: '/products-plans/school?name=Monitor',
          },
          {
            label: '晓羊学苑',
            route: '/products-plans/school?name=College',
          },
          {
            label: '教师发展平台',
            route: '/products-plans/school?name=Teacher',
          },
          {
            label: '学业质量管理平台',
            route: '/products-plans/school?name=AcademicQuality',
          },
        ],
      },
      {
        label: '区域产品',
        route: '/products-plans/area',
        children: [
          {
            label: '区域课程管理平台',
            route: '/products-plans/area?name=CourseManage',
          },
          {
            label: '区域校园安全风险防控平台',
            route: '/products-plans/area?name=Safety',
          },
          {
            label: '区域课后服务云平台',
            route: '/products-plans/area?name=AfterSchool',
          },
          {
            label: '区域学业质量管理平台',
            route: '/products-plans/area?name=AcademicQuality',
          },
          {
            label: '区域智能物联管控平台',
            route: '/products-plans/area?name=SmartUnion',
          },
        ],
      },
      {
        label: '整体解决方案',
        route: '/products-plans/solution',
        children: [
          {
            label: '新高考一站式解决方案',
            route: '/products-plans/solution?name=NewCollegeEntrance',
          },
          {
            label: '集团校管理解决方案',
            route: '/products-plans/solution?name=SchoolGroups',
          },
          {
            label: '五育并举评价整体解决方案（小学版）',
            route: '/products-plans/solution?name=ElementarySchoolVersion',
          },
          {
            label: '五育并举评价整体解决方案（中学版）',
            route: '/products-plans/solution?name=SecondarySchoolVersion',
          },
          {
            label: '绿色校园整体解决方案',
            route: '/products-plans/solution?name=GreenCampus',
          },
          {
            label: '平安校园整体解决⽅案',
            route: '/products-plans/solution?name=SafeCampus',
          },
          {
            label: '智慧研学综合实践一站式解决方案',
            route: '/products-plans/solution?name=SmartStudy',
          },
          {
            label: '家校社一体化劳动教育解决方案',
            route: '/products-plans/solution?name=Integrated',
          },
          // {
          //   label: '晓慧XMUI AIoT平台解决方案',
          //   route: '/products-plans/solution?name=XiaohuiAIOT',
          // },
          {
            label: '教师发展解决方案',
            route: '/products-plans/solution?name=TeacherDevelopment',
          },
          {
            label: '课后服务解决方案',
            route: '/products-plans/solution?name=AfterClassService',
          },
          {
            label: '智笔课堂互动解决方案',
            route: '/products-plans/solution?name=IntelligentPenClassroom',
          },
          {
            label: '学业质量管理解决方案',
            route: '/products-plans/solution?name=AcademicQuality',
          },
        ],
      },
    ],
  },
  {
    label: '应用场景',
    primary: true,
    route: '/scene',
    children: [
      {
        label: '学生个性成长',
        route: '/scene/student-grow',
      },
      {
        label: '教师专业发展',
        route: '/scene/teacher-development',
      },
      {
        label: '教育管理与评价',
        route: '/scene/education-manage_evaluate',
      },
      {
        label: '绿色校园',
        route: '/scene/green_campus',
      },
      {
        label: '平安校园',
        route: '/scene/safe_campus',
      },
      {
        label: '社会服务',
        route: '/scene/social_service',
      },
    ],
  },
  {
    label: '服务与支持',
    primary: true,
    route: '/service',
    children: [
      {
        label: '服务解读',
        route: '/service#interpretaion',
      },
      {
        label: '服务能力',
        route: '/service#ability',
      },
      {
        label: '服务地图',
        route: '/service#map',
      },
    ],
  },
  {
    label: '研究院',
    primary: true,
    route: '/institute',
    children: [
      {
        label: '晓羊教育研究院',
        route: '/institute/intro#overview',
      },
      // {
      //   label: '资源优势',
      //   route: '/institute/intro#resource',
      // },
      // {
      //   label: '核⼼业务',
      //   route: '/institute/intro#core',
      // },
      // {
      //   label: '教育研修项目类型',
      //   route: '/institute/intro#edu_type',
      // },
      {
        label: '晓羊技术研究院',
        route: '/institute/technology_Institute',
      },
      {
        label: '内刊',
        route: '/institute/internal_publication',
      },
    ],
  },
  {
    label: '新闻中心',
    primary: true,
    route: '/news',
    children: [
      {
        label: '集团动态',
        route: '/news/group-dynamics',
      },
      {
        label: '行业新闻',
        route: '/news/trade-news',
      },
      {
        label: '政策追踪',
        route: '/news/policy-track',
      },
      {
        label: '晓羊公益行',
        route: '/news/public-benefit',
      },
    ],
  },
]
