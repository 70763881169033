/**
 * 裁剪文字
 * @param {string} text - 文字
 * @param {number} length - 保留长度
 * @param {string} location - 位置
 * @param {string} [symbol = '...'] - 替代符号
 */
export const cutString = ({
  text,
  length,
  location = 'end',
  symbol = '...',
}) => {
  if (text.length > length) {
    switch (location) {
      case 'start':
        return `${symbol}${text.substring(text.length - length, text.length)}`
      case 'center':
        return `${text.substring(
          0,
          Math.floor(length / 2)
        )}${symbol}${text.substring(
          text.length - Math.ceil(length / 2),
          text.length
        )}`
      case 'end':
        return `${text.substring(0, length)}${symbol}`
      default:
        break
    }
  }
  return text
}

/**
 * 判断元素是否已经到达视区
 * @description 网页可视高度 - （ （ 元素实际距离顶部高度 ）- 页面滚动卷去高度 ） - 元素高度
 */
export const isElementInViewer = el => {
  const eleTopHeight =
    document.getElementsByClassName('top').length > 0
      ? document.getElementsByClassName('top')[0].offsetHeight
      : 0
  const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
  return (
    document.documentElement.clientHeight -
      (el.offsetTop + eleTopHeight - scrollTop) -
      el.clientHeight >
    0
  )
}
