<template>
  <div
    id="app"
    :class="[$store.state.deviceWidth > 768 ? 'view-pc' : 'view-mobile']"
  >
    <component
      :is="$route.meta.hasHeader !== false ? 'Header' : null"
    ></component>
    <keep-alive include="经典案例">
      <router-view></router-view>
    </keep-alive>
    <component
      :is="$route.meta.hasHeader !== false ? 'Footer' : null"
    ></component>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },
  watch: {
    '$route.hash'(hash) {
      if (hash && document.getElementById(`${hash.slice(1)}`)) {
        window.scrollTo(
          0,
          document.getElementById(`${hash.slice(1)}`).offsetTop - 160
        )
      }
    },
    $route: {
      handler(val) {
        if (
          val.path.indexOf('service') === -1 &&
          val.path.indexOf('partners-customers/case') === -1
        ) {
          document.body.scrollTop = document.documentElement.scrollTop = 0
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  mounted() {
    // 判断设备场景
    this.judgeDeviceWidth()
    window.onresize = () => {
      this.judgeDeviceWidth()
    }
    // 重设卷去距离
    window.onscroll = () => {
      this.$store.commit(
        'setScrollTop',
        document.body.scrollTop + document.documentElement.scrollTop
      )
    }
    // 获取基本信息
    this.$store.dispatch('setBaseInfoAsync')
  },
  methods: {
    /**
     * 判断设备尺寸
     */
    judgeDeviceWidth() {
      this.$store.commit('setDeviceWidth', window.innerWidth)
    },
  },
}
</script>

<style lang="scss">
/* 通用 */
html {
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

/* 区块 */
%box {
  padding: 60px 0 126px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    box-sizing: border-box;
    h1,
    h2,
    h3,
    h4,
    p {
      color: #333;
    }

    &:after {
      content: '';
      width: 66px;
      height: 3px;
      margin-top: 22px;
      background-color: $--color-primary;
    }

    > * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;

      &:nth-child(1) {
        font-size: 40px;
        font-weight: bolder;
      }

      &:nth-child(2) {
        font-size: 24px;
        font-weight: bolder;
        margin-top: 18px;
      }
    }
  }
}

/* PC设备 */
#app.view-pc {
  max-width: 2560px;
  min-width: 320px;
  height: 100vh;
  margin: 0 auto;
  // .content {
  //   width: 95%;
  //   margin: 0 auto;
  // }

  h5 {
    font-size: 22px !important;
  }
  .pc {
    display: inherit;
  }
  .mobile {
    display: none;
  }
  .container {
    width: 100%;
    max-width: 1320px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .container-fluid {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  section.box {
    @extend %box;
  }
}

/* mobile设备 */
#app.view-mobile {
  .container {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .pc {
    display: none;
  }
  .mobile {
    display: inherit;
  }
  h3,
  h4 {
    font-size: 22px !important;
  }
  h5 {
    font-size: 16px !important;
  }

  section.box {
    @extend %box;
    padding: 30px 0 30px;

    .title {
      margin-bottom: 30px;
      &:after {
        width: 30px;
        height: 3px;
        margin-top: 10px;
      }

      > * {
        &:nth-child(1) {
          font-size: 22px;
        }

        &:nth-child(2) {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
