import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
/**
 *  utils 工具包
 */
import * as Methods from '@/utils/Methods'
import * as axios from '@/utils/Request'

/**
 * 引入站点样式
 */
import 'normalize.css'
import '@/assets/styles/common.scss' // 全局样式
/**
 * 第三方包
 */
//Carousel, CarouselItem
import {
  Button,
  Col,
  Dialog,
  Row,
  Icon,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Radio,
  Checkbox,
  Message,
} from 'element-ui' //element-ui
import '@/assets/styles/theme/element-variables.scss'
//video播放器
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
// 日期处理插件
import Moment from 'moment'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
  fullscreenEl: false,
  closeEl: false, // 控制是否显示右上角关闭按钮
  tapToClose: true, // 点击滑动区域应关闭图库
  shareEl: false, // 控制是否显示分享按钮
  zoomEl: false, // 控制是否显示放大缩小按钮
  counterEl: false, // 控制是否显示左上角图片数量按钮
  arrowEl: true, // 控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, // 点击应切换控件的可见性
  clickToCloseNonZoomable: true, // 点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, options)
Vue.config.productionTip = false
// 全局挂载方法
Vue.prototype.$axios = axios
Vue.prototype.$methods = Methods
Vue.prototype.$moment = Moment
Vue.prototype.$message = Message
// use
Vue.use(Meta)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(Checkbox)
// Vue.use(Message)
// Vue.use(Carousel)
// Vue.use(CarouselItem)
Vue.use(VueVideoPlayer)
// Vue.use(VueAwesomeSwiper)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // 解决无法找到任何路由的报错
    // document.dispatchEvent(new Event('render-event'))
    process.env.NODE_ENV === 'production'
      ? document.dispatchEvent(new Event('custom-render-trigger'))
      : () => {} // 预渲染
  },
}).$mount('#app')
